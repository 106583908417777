<template>
  <div class="col-12 col-lg-6 c g">
    <div class="card card-body">
      <div class="form-group">
        <label for="">رابط دخول المشرفين</label>
        <input type="text" :value="url"
          class="form-control bg-white border shadow" dir="ltr" readonly>
      </div>
    </div>
    <div class="card card-body">
        <div class="col-12 table-responsive">
            <div class="g col-12 text-center"><button class="btn btn-primary" v-b-modal.modal-1 @click="current = {pers:[], devices: [], tags: []}"><i class="fa fa-plus"></i> اضافة مشرف</button></div>
            <br>
            <table class="table table-hover table-bordered">
                <tbody>
                    <tr v-for="admin in admins" :key="admin._id">
                      <td @click="current = admin;" v-b-modal.modal-1 style="font-size: 20px">
                          {{ admin.name == "" ? 'بدون اسم' : admin.name }}
                      </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <b-modal id="modal-1" title="" hide-footer>
        <div class="form-group">
          <h5 for="">اسم المشرف</h5>
          <input type="text"
            class="form-control" v-model="current.name">
        </div>
        <div class="form-group">
          <h5 for="">الجوال</h5>
          <input type="text"
            class="form-control" v-model="current.phone">
        </div>
        <div class="form-group">
          <h5 for="">كلمة المرور</h5>
          <input type="password"
            class="form-control" v-model="current.password">
        </div>
        <div class="col-12 g">
          <div class="card card-body border">
            <ul>
              <li v-for="per in pers" :key="per.code">
                <div class="form-check">
                  <label class="form-check-label">
                    <input type="checkbox" class="form-check-input" :value="per.code" v-model="current.pers">
                    {{ per.title }}
                  </label>
                </div>
              </li>
            </ul>
          </div>
          <div class="card card-body border">
            <ul>
              <li v-for="device in devices" :key="device._id">
                <div class="form-check">
                  <label class="form-check-label">
                    <input type="checkbox" class="form-check-input" :value="device._id" v-model="current.devices">
                    {{ device.title }}
                  </label>
                </div>
              </li>
            </ul>
          </div>
          <div class="card card-body border">
            <p>صلاحية التصنيفات (اذا لم تحدد تصنيفات فسيتم السماح بالكل)</p>
            <ul>
              <li v-for="tag in tags" :key="tag._id">
                <div class="form-check">
                  <label class="form-check-label">
                    <input type="checkbox" class="form-check-input" :value="tag._id" v-model="current.tags">
                    {{ tag.name }}
                  </label>
                </div>
              </li>
            </ul>
          </div>
          <div class="col-12 text-center g">
            <button class="btn btn-primary" @click="save()">
              حفظ
            </button>
            <div v-if="current._id">
              <br><br>
              <button class="btn btn-sm btn-outline-danger" @click="deleteAdmin(current._id)">
                <i class="fa fa-trash"></i>
                حذف المشرف
              </button>
            </div>
          </div>
        </div>
    </b-modal>
  </div>
</template>

<script>
import { BDropdown, BDropdownItem } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BDropdown,
    BDropdownItem,
  },
  directives: {
    Ripple,
  },
  created(){
    var g = this;
    g.getAdmins()
  },
  data(){
    return {
        jwt: JSON.parse(localStorage.getItem("user")).jwt,
        admins: [],
        url: window.location.href.split("/admins")[0],
        current: {pers: [], devices: [], tags: []},
        devices: [],
        tags: [],
        pers: [
          {
            code: "*",
            title: "*كل الصلاحيات*"
          },
          {
            code: "admins",
            title: "المشرفين"
          },
          {
            code: "devices",
            title: "الاجهزة"
          },
          {
            code: "الاحصائيات",
            title: "stats"
          },
          {
            code: "الردود التلقائية",
            title: "auto-replies"
          },
          {
            code: "الذكاء الاصطناعي",
            title: "ai"
          },
          {
            code: "tags",
            title: "صتنيفات العملاء"
          },
          {
            code: "new-chats",
            title: "المحادثات: استعراض الماحدثات الجديدة"
          },
          {
            code: "old-chats",
            title: "المحادثات: استعراض الماحدثات القديمة"
          },
          {
            code: "no-tags",
            title: "استعراض المحادثات التي بدون تصنيف"
          },
          {
            code: "show-numbers",
            title: "استعراض ارقام العملاء"
          },
          {
            code: "settings",
            title: "الاعدادات"
          },
          {
            code: "templates",
            title: "الردود الجاهزة"
          },
        ]
    }
  },
  methods: {
    getAdmins(){
      var g = this;
      $.post(api + '/user/admins/list', {
          jwt: this.jwt
      }).then(function(r){
          if(r.status != 100){
              alert(r.response)
          }else{
              g.admins = r.response
          }
      }).fail(function(){
          alert("حدث مشكلة في الاتصال")
      })
      $.post(api + '/user/tags/list', {
          jwt: this.jwt
      }).then(function(r){
          if(r.status != 100){
              alert(r.response)
          }else{
              g.tags = r.response
          }
      }).fail(function(){
          alert("حدث مشكلة في الاتصال")
      })
      $.post(api + '/user/devices/list', {
          jwt: this.jwt
      }).then(function(r){
          if(r.status != 100){
              alert(r.response)
          }else{
              g.devices = r.response
          }
      }).fail(function(){
          alert("حدث مشكلة في الاتصال")
      })
    },
    save(){
      var g = this;
      if(!this.current.pers){
        this.current.pers = []
      }
      if(!this.current.devices){
        this.current.devices = []
      }
      $.post(api + '/user/admins/save', {
          jwt: this.jwt,
          id: this.current._id,
          name: this.current.name,
          phone: this.current.phone,
          password: this.current.password,
          pers: this.current.pers,
          devices: this.current.devices,
          tags: this.current.tags,
      }).then(function(r){
          if(r.status != 100){
              alert(r.response)
          }else{
              $("#modal-1___BV_modal_header_ > button").click()
              g.getAdmins()
          }
      }).fail(function(){
          alert("حدث مشكلة في الاتصال")
      })
    },
    deleteAdmin(id){
      if(confirm("متاكد من حذف المشرف؟")){
        var g = this;
        $.post(api + '/user/admins/delete', {
            jwt: this.jwt,
            id: id
        }).then(function(r){
            if(r.status != 100){
                alert(r.response)
            }else{
                $("#modal-1___BV_modal_header_ > button").click()
                g.getAdmins()
            }
        }).fail(function(){
            alert("حدث مشكلة في الاتصال")
        })
      }
    }
  }
}
</script>

<style>

</style>